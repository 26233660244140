import React from "react"
import {  graphql } from "gatsby"
import PageLayout from '../components/PageLayout.js';
import Typography from '@mui/material/Typography';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TermList from '../components/TermListB.js';
import Heading from '../components/Heading';
import TwoColumnLayout from '../components/TwoColumnLayout';
import CardMedia from '@mui/material/CardMedia';
import Seo from "../components/Seo";


const Category = ({data}) => {
  const category = data.page;
  const terms = data.category.contentNodes.nodes.sort(function(a, b) {
    var orderBool = a.title.toLowerCase() > b.title.toLowerCase();
    return orderBool ? 1 : -1;
  });
  const edit = false;

  return (
      <PageLayout>
        <Seo title={ category.title} />
          <Heading
              title={category.title}
              url={"/" + category.slug.replace("headstart-", "")}
              postId={category.databaseId}
              edit={edit}
          />

          <TwoColumnLayout>
              <>
              <CardMedia
                            component="img"
                            height="200"
                            image={category.featuredImage.node.mediaItemUrl}

                        />
              <Typography component="div">
              <span className="bodyContent" dangerouslySetInnerHTML={{ __html: category.content }} />
              </Typography>
                  <div className="noPrint">
                      <Typography component="p" sx={{ fontWeight: 'bold', display: { xs: 'none', md: 'none' } }}>Browse terms using the list to the right &gt;</Typography>

                      {terms.length > 0 &&
                          <Box sx={{ pt: 1, pb: 2 }}>
                              <Button sx={{ pr: .5 }} variant="outlined" href={"/term/" + terms[0].slug}>First Term<ArrowRight /></Button>
                          </Box>
                      }
                  </div>
              </>
              <div className="noPrint">
                  <Typography component="h4" variant="h4" >
                      Terms
                  </Typography>
                  <TermList terms={terms} />
              </div>
          </TwoColumnLayout>
   </PageLayout>
  );

}

export default Category

export const pageQuery = graphql`
query CategoryQuery($slug: String!){
  page: wpPage(slug: {eq: $slug}) {
    id
    uri
    title
    content
    parentId
    databaseId
    uri
    slug
    headstartPages {
      abstract
    }
    featuredImage {
			node {
				mediaItemUrl
      }
    }
  }
  category: wpCategory(slug: {eq:$slug}) {
    id
    contentNodes {
      nodes {
        slug
        link
        ... on WpPost {
          id
          title
        }
      }
    }
    description
    name
    uri
  }
}
`

